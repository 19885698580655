<template>
  <div class="container-fluid mb-4 pb-4">

    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>รายชื่อผู้ช่วย</strong>
      <div style="width: 250px;">
        <input type="text" v-model="filter" class="form-control form-control-sm" placeholder="ค้นหา...">
      </div>
    </div>

    <div class="card mb-1">

      <b-table
        class="table-account"
        :fields="fields"
        :items="items"
        :show-empty="true"
        :fixed="true"
        empty-text="ไม่มีข้อมูล"
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          {{ (perPage*(currentPage-1)) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <span>
            <span>{{ data.item.username }}</span>
          </span>
        </template>
        <template #cell(firstName)="data">
          {{ data.item.firstName }}
        </template>
        <template #cell(lastName)="data">
          {{ data.item.lastName || '-' }}
        </template>
        <template #cell(createdAt)="data">
          <span>{{ dateFormat(data.item.createdAt, 'DD/MM/YYYY HH:mm:ss') }}</span>
        </template>
        <template #cell(lastActive)="data">
          <span>{{ dateFormat(data.item.lastActive, 'DD/MM/YYYY HH:mm:ss') }}</span>
        </template>
        <template #cell(status)="data">
          <AssistantStatus :status="data.item.status" />
        </template>
        <template #cell(note)="data">
          <span v-if="data.item.status==='Blocked'">{{data.item.blocked.blockedNote}}</span>
        </template>
        <template #cell(manage)="data">
          <b-dropdown class="m-0 p-0 btn-edit" size="sm" variant="link" right>
            <template slot="button-content">
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item @click="setPermission(data.item)">
              <template>
                <span class="text-primary"><i class="fas fa-user-shield"></i> ตั้งค่าสิทธิการใช้งาน</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item @click="editProfile(data.item)">
              <template>
                <span class="text-primary"><i class="far fa-address-card"></i> แก้ไขข้อมูลส่วนตัว</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item @click="changePassword(data.item)">
              <template>
                <span class="text-primary"><i class="fas fa-key"></i> เปลี่ยนรหัสผ่าน</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="data.item.status==='Active'" @click="blockAssistant(data.item)">
              <template>
                <span class="text-danger"><i class="fas fa-ban"></i> ระงับ</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status==='Blocked'" @click="unBlockAssistant(data.item)">
              <template>
                <span class="text-success"><i class="fas fa-undo-alt"></i> ใช้งาน</span>
              </template>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      align="right"
      size="sm"
      class="my-0"
    ></b-pagination>

    <ChangePasswordModal :data="changePasswordItem" :is-show="isShowPasswordModal" @close="modalClosed" />
    <EditProfileModal :data="editItem" :is-show="isShowEditModal" @close="modalClosed" />
    <BlockAssistantModal :data="blockItem" :is-show="isShowBlockModal" @close="modalClosed" />
    <UnBlockAssistantModal :data="unBlockItem" :is-show="isShowUnBlockModal" @close="modalClosed" />
    <PermissionModal :data="permissionItem" :is-show="isShowPermissionModal" @close="modalClosed" />
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import AssistantService from '@/services/AssistantService'
import moment from '@/helpers/moment'

import ChangePasswordModal from '@/views/manage-account/components/ChangePasswordModal'
import EditProfileModal from '@/views/manage-account/components/EditProfileModal'
import BlockAssistantModal from './components/BlockAssistantModal'
import AssistantStatus from './components/AssistantStatus'
import UnBlockAssistantModal from './components/UnBlockAssistantModal'
import PermissionModal from './components/PermissionModal'

export default {
  name: 'Assistants',
  components: {
    ChangePasswordModal,
    EditProfileModal,
    BlockAssistantModal,
    AssistantStatus,
    UnBlockAssistantModal,
    PermissionModal
  },
  data() {
    return {
      data: null,
      fields: [
        {key: 'index', label: 'ลำดับ', thStyle: { width: "60px" }},
        {key: 'username', label: 'ชื่อผู้ใช้'},
        {key: 'firstName', label: 'ชื่อ'},
        {key: 'lastName', label: 'นามสกุล'},
        {key: 'createdAt', label: 'วันที่เพิ่ม'},
        {key: 'lastActive', label: 'ใช้งานล่าสุด'},
        {key: 'status', label: 'สถานะ'},
        {key: 'note', label: 'หมายเหตุ'},
        {key: 'manage', label: '', tdClass: "text-right"}
      ],
      perPage: 20,
      currentPage: 1,
      filter: '',
      /**
       * เปลี่ยรหัสผ่าน
       */
      isShowPasswordModal: false,
      changePasswordItem: {
        _id: null,
        username: null,
        nPassword: null,
        cPassword: null
      },
      /**
       * แก้ไขข้อมูลส่วนตัว
       */
      isShowEditModal: false,
      editItem: {
        _id: null,
        username: null,
        firstName: null,
        lastName: null,
        tel: null,
        lineId: null
      },
      /**
       * ระงับการใช้งาน
       */
      isShowBlockModal: false,
      blockItem: {
        _id: null,
        username: null,
        blockNote: null
      },
      /**
       * ยกเลิกระงับการใช้งาน
       */
      isShowUnBlockModal: false,
      unBlockItem: {
        _id: null,
        username: null,
        blockNote: null
      },
      /**
       * สิทธิการใช้งาน
       */
      isShowPermissionModal: false,
      permissionItem: {}
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  methods: {
    loadData() {
      AssistantService.getAssistants()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
    },
    onFiltered() {
      this.currentPage = 1
    },
    dateFormat(date, format='YYYY-MM-DD') {
      if(date) {
        return moment(date).format(format)
      }else{
        return '-'
      }
    },
    modalClosed(needReload) {
      this.isShowPasswordModal = false
      this.isShowEditModal = false
      this.isShowBlockModal = false
      this.isShowUnBlockModal = false
      this.isShowPermissionModal = false
      if(needReload) {
        this.loadData()
      }
    },
    setPermission(item) {
      this.permissionItem = item
      this.isShowPermissionModal = true
    },
    editProfile(item) {
      this.editItem = {
        ...this.editItem,
        ...item
      }
      this.isShowEditModal = true
    },
    changePassword(item) {
      this.changePasswordItem = {
        ...this.changePasswordItem,
        _id: item._id,
        username: item.username
      }
      this.isShowPasswordModal = true
    },
    blockAssistant(item) {
      this.blockItem = {
        _id: item._id,
        username: item.username,
        blockNote: ''
      }
      this.isShowBlockModal = true
    },
    unBlockAssistant(item) {
      this.unBlockItem = {
        _id: item._id,
        username: item.username,
        blockNote: ''
      }
      this.isShowUnBlockModal = true
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss">
.table-account {
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-size: 90%;
        text-align: center;
      }

      th:first-child {
        width: 80px;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: center;
        font-size: 90%;

        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
  button.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.dropdown-menu-right {
    li {
      font-size: 90%;
    }
  }
}
</style>
